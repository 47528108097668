import React from 'react'

import { cn } from '../utils'

interface SteppedProgressProps {
  total: number
  current: number
}
export const SteppedProgress: React.FC<SteppedProgressProps> = ({
  total,
  current: currentIndex,
}) => {
  const steps = Array.from({ length: total }, (_, index) => index + 1)
  const currentStep = currentIndex + 1

  return (
    <div className="flex flex-row space-x-5 items-center">
      {steps.map((step) => {
        return (
          <React.Fragment key={step}>
            <div
              className={cn(
                'w-9 h-9 flex items-center justify-center rounded-full',
                {
                  'border-[1.5px] border-neutral-950 dark:border-primary':
                    step === currentStep,
                },
              )}
            >
              <div
                className={cn(
                  'rounded-full h-7 w-7 flex items-center justify-center text-sm font-medium',
                  {
                    'bg-neutral-200 text-primary dark:bg-neutral-800 dark:text-primary/60':
                      step > currentStep,
                    'bg-neutral-950 text-background dark:bg-primary':
                      step <= currentStep,
                  },
                )}
              >
                {step}
              </div>
            </div>
            {step < total ? <div className="h-0.5 w-10 bg-primary" /> : null}
          </React.Fragment>
        )
      })}
    </div>
  )
}
