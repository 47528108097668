'use client'

import type { ButtonProps } from './'
import type { VariantProps } from 'cva'
import type * as React from 'react'

import { Close as DialogPrimitiveClose } from '@radix-ui/react-dialog'
import { cva } from 'cva'

import { DialogTrigger } from './Dialog'

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  Icon,
  Spinner,
} from './'

const ModalTrigger = DialogTrigger
ModalTrigger.displayName = 'ModalTrigger'
type ControlledOpenProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => unknown
}

type TriggerProps = {
  Trigger?: React.ReactNode
}

type Props = React.PropsWithChildren<
  {
    heading: React.ReactNode
    description?: React.ReactNode
    footer?: React.ReactNode
    isLoading?: boolean
    closeButton?: boolean
    className?: string
  } & VariantProps<typeof variants.modal> &
    (ControlledOpenProps | TriggerProps)
>

const variants = {
  modal: cva(
    [
      // 'inline-block max-h-[90%] w-full transform overflow-auto rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-dark-800 relative',
      'inline-block w-full transform overflow-auto rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-dark-800 relative',
    ],
    {
      variants: {
        size: {
          default: 'max-w-xs',
          medium: 'max-w-md',
          large: 'max-w-2xl min-w-[96%]',
          xl: 'max-w-3xl min-w-[96%]',
          '2xl': 'max-w-4xl min-w-[96%]',
          '3xl': 'max-w-5xl min-w-[96%]',
        },
        defaultVariants: {
          size: 'default',
        },
      },
    },
  ),
}

const DialogWrapper = ({
  isControlled,
  useCloseButton,
  isOpen,
  setIsOpen,
  children,
}: React.PropsWithChildren<{
  isControlled: boolean
  useCloseButton: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => unknown
}>) =>
  isControlled ? (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (useCloseButton && !open) {
          setIsOpen(false)
        }
      }}
    >
      {children}
    </Dialog>
  ) : (
    <Dialog>{children}</Dialog>
  )

export const Modal: React.FC<Props> & {
  CancelButton: typeof CancelButton
  Trigger: typeof ModalTrigger
} = ({
  size = 'default',
  closeButton,
  heading,
  footer,
  description,
  isLoading,
  children,
  className,
  ...rest
}) => {
  const isControlled = 'isOpen' in rest && rest.isOpen !== undefined
  const useCloseButton = closeButton ?? true

  const {
    isOpen = true,
    setIsOpen = () => {},
    Trigger = null,
    ...props
  } = rest as ControlledOpenProps & TriggerProps

  return (
    <DialogWrapper
      {...props}
      isControlled={isControlled}
      useCloseButton={useCloseButton}
      // defaultOpen={isControlled ? isOpen : true}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {Trigger && <ModalTrigger>{Trigger}</ModalTrigger>}

      <DialogContent className={className}>
        <div className="px-4 text-center">
          <span className="inline-block  align-middle" aria-hidden="true">
            &#8203;
          </span>

          <div className={variants.modal({ size })}>
            {isLoading && (
              <div className="absolute top-0 bottom-0 right-0 left-0 bg-zinc-900/10 z-[51] flex items-center justify-center">
                <Spinner size="large" />
              </div>
            )}
            <div className={'flex flex-col gap-6'}>
              <div className="flex flex-col gap-2">
                <DialogTitle className="w-full">{heading}</DialogTitle>
                {description && (
                  <DialogDescription>{description}</DialogDescription>
                )}
              </div>

              <div className="relative">{children}</div>

              {useCloseButton && (
                <DialogPrimitiveClose asChild>
                  <button
                    className={
                      'absolute top-6 right-6 flex items-center cursor-pointer'
                    }
                    title={'Close Modal'}
                    onClick={() => {
                      if (isControlled) {
                        setIsOpen(false)
                      }
                    }}
                  >
                    <Icon name="XMark" size="medium" />
                  </button>
                </DialogPrimitiveClose>
              )}
            </div>
            {footer && <DialogFooter>{footer}</DialogFooter>}
          </div>
        </div>
      </DialogContent>
    </DialogWrapper>
  )
}

const CancelButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  ...restProps
}) => {
  return (
    <Button
      type={'button'}
      data-cy={'close-modal-button'}
      variant={'outline'}
      {...restProps}
    >
      {children || 'Cancel'}
    </Button>
  )
}

Modal.CancelButton = CancelButton
Modal.Trigger = ModalTrigger
