'use client'

import classNames from 'clsx'
import { forwardRef, useCallback } from 'react'

import { INPUT_CLASS_NAMES } from '../lib/inputClassNames'

export const Textarea = forwardRef<
  React.ElementRef<'textarea'>,
  React.TextareaHTMLAttributes<unknown> & {
    autoResize?: boolean
    hasError?: boolean
  }
>(function TextareaComponent({ className, hasError, ...props }, ref) {
  const onInput = useAutoResize(props.onInput)

  return (
    <textarea
      ref={ref}
      {...props}
      onInput={props.autoResize ? onInput : props.onInput}
      className={classNames(
        INPUT_CLASS_NAMES,
        'min-h-[60px] flex flex-auto resize-none',
        className,
        {
          [`cursor-not-allowed bg-gray-100 hover:bg-gray-100 dark:bg-dark-800`]:
            props.disabled,
        },
        { '!border-pink-500': hasError },
      )}
    />
  )
})

function useAutoResize(onInput?: React.FormEventHandler<HTMLTextAreaElement>) {
  const callback: React.FormEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      const target = event.currentTarget

      target.style.height = ''
      target.style.height = target.scrollHeight + 'px'

      if (onInput) {
        onInput(event)
      }
    },
    [onInput],
  )

  return callback
}
