'use client'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import * as React from 'react'

import { cn } from '../utils'

import { StaticTooltip } from './StaticTooltip'

const Tooltip = ({ delayDuration = 0, ...props }) => (
  <TooltipPrimitive.Provider delayDuration={delayDuration}>
    <TooltipPrimitive.Root {...props} />
  </TooltipPrimitive.Provider>
)

Tooltip.displayName = TooltipPrimitive.Tooltip.displayName

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    collisionPadding={4}
    className={cn(
      `animate-in fade-in-100 data-[side=bottom]:slide-in-from-top-1
         data-[side=top]:slide-in-from-bottom-1
         data-[side=left]:slide-in-from-right-1
         data-[side=right]:slide-in-from-left-1 z-50`,
      className,
    )}
    {...props}
  >
    <StaticTooltip>{children}</StaticTooltip>
  </TooltipPrimitive.Content>
))

TooltipContent.displayName = TooltipPrimitive.Content.displayName

const TooltipPortal = TooltipPrimitive.TooltipPortal
TooltipPortal.displayName = TooltipPrimitive.TooltipPortal.displayName

export { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger }
