import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'cva'
import React from 'react'

import { cn } from '../utils'

const labelVariants = cva(
  'text-xs w-full dark:text-neutral-100 peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
)
const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(
      labelVariants(),
      'group-aria-invalid:text-pink-500',
      className,
    )}
    {...props}
  />
))
Label.displayName = LabelPrimitive.Root.displayName
export { Label }
