import type React from 'react'
import type Run from 'types/src/run'

import { runResultConfig } from '../lib/runResultConfig'

import { RunStatusBadge } from './RunStatusBadge'
import { ThinBadge } from './ThinBadge'

interface RunResultBadgeProps {
  run: Pick<Run, 'result' | 'status'>
}

export const RunResultBadge: React.FC<RunResultBadgeProps> = ({ run }) => {
  if (run.status !== 'COMPLETED') {
    return <RunStatusBadge run={run} />
  }

  if (run.result === null) {
    return <div>-</div>
  }

  return (
    <ThinBadge colorClasses={runResultConfig[run.result].colorClasses}>
      {runResultConfig[run.result].label}
    </ThinBadge>
  )
}
