'use client'

import type * as React from 'react'
import type { MouseEventHandler, PropsWithChildren } from 'react'

import Link from 'next/link'

import { Button } from './Button'
import { Card, CardContent } from './Card'
import { Heading } from './Heading'
import { Icon } from './Icon'
import { Separator } from './Separator'
import { Text } from './Text'

/**
 * This component shouldn't be here in UI package. It should live somewhere in
 * SaaS app (ex: /apps/saas/src/core/ui). The reason it is here is that at the
 * moment it was developed there wasn't enough server data to render it, so we
 * decided to store it in UI package and render in storybook until there is
 * relevant data, so it can be rendered properly on SaaS dashboard.
 */
export const OnboardingChecklist: React.FC<
  PropsWithChildren<{
    userName: string
    onComplete: MouseEventHandler<HTMLButtonElement>
  }>
> = ({ userName, children }) => (
  <Card className="p-3">
    <CardContent className={'space-y-3'}>
      <div>
        <Heading size="small" className="mb-1">
          Welcome, {userName}
        </Heading>
        <Text size="small">
          Make sure to complete the following points to get the full experience.
        </Text>
      </div>
      <div>{children}</div>
      <Separator />
      <Button size="block">Complete Onboarding</Button>
    </CardContent>
  </Card>
)
OnboardingChecklist.displayName = 'OnboardingChecklist'

interface OnboardingChecklistPropsBase {
  checked?: boolean
  title: string
  actionLabel: string
}

interface OnboardingChecklistPropsWithAction
  extends OnboardingChecklistPropsBase {
  onClick: MouseEventHandler<HTMLButtonElement>
  href?: never
}

interface OnboardingChecklistPropsWithHref
  extends OnboardingChecklistPropsBase {
  href: string
  onClick?: never
}
export const OnboardingItem: React.FC<
  OnboardingChecklistPropsWithAction | OnboardingChecklistPropsWithHref
> = ({ checked, title, actionLabel, onClick, href }) => {
  return (
    <div className="border-t border-border p-3 flex flex-col space-y-3 -mx-3">
      <div className="flex flex-row justify-start space-x-2">
        {checked ? (
          <div className="w-5 h-5 rounded-full bg-neutral-950 dark:bg-white flex justify-center items-center">
            <Icon
              name="Check"
              size="extra-small"
              className="text-white dark:text-neutral-950 stroke-[3px]"
            />
          </div>
        ) : (
          <div className="w-5 h-5 border-[1.5px] border-border rounded-full" />
        )}
        <Text>{title}</Text>
      </div>
      {!checked && (
        <>
          {onClick && (
            <Button
              size="small"
              variant="outline"
              onClick={onClick}
              className="w-full"
            >
              {actionLabel}
            </Button>
          )}
          {href && (
            <Link href={href}>
              <Button variant="outline" size="small" className="w-full">
                {actionLabel}
              </Button>
            </Link>
          )}
        </>
      )}
    </div>
  )
}
