import type { buttonVariants } from './Button'
import type { VariantProps } from 'cva'
import type { PropsWithChildren } from 'react'
import type React from 'react'

import clsx from 'clsx'

import { Button } from './Button'
import { Modal } from './Modal'

interface ConfirmDialogProps extends VariantProps<typeof buttonVariants> {
  isOpen: boolean
  setIsOpen: (state: boolean) => void
  onDismiss?: () => void
  confirmLabel?: React.ReactNode
  dismissLabel?: string
  onConfirm: () => void
  title?: string
  description?: string
  closeButton?: boolean
  isLoading?: boolean
}
export const ConfirmDialog: React.FC<PropsWithChildren<ConfirmDialogProps>> = ({
  isOpen,
  setIsOpen,
  title,
  description,
  children,
  confirmLabel,
  dismissLabel,
  isLoading,
  onConfirm,
  onDismiss,
  variant = 'default',
}) => {
  function onCancel() {
    onDismiss?.()
    setIsOpen(false)
  }

  return (
    <Modal
      heading={title}
      description={description}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeButton={true}
      size="large"
    >
      <div className="pb-6">{children}</div>
      <div className={'flex justify-end space-x-2'}>
        <Modal.CancelButton onClick={onCancel} className="min-w-[120px]">
          {dismissLabel || 'Cancel'}
        </Modal.CancelButton>
        <Button
          loading={isLoading}
          variant={variant}
          onClick={onConfirm}
          className="min-w-[120px]"
        >
          {confirmLabel || 'OK'}
        </Button>
      </div>
    </Modal>
  )
}
