import type { IconName } from './Icon'
import type { ReactNode } from 'react'

import { clsx as classNames } from 'clsx'
import { cva } from 'cva'

import { Icon } from './Icon'
import { Spinner } from './Spinner'

export type BadgeColor =
  | 'normal'
  | 'inverted'
  | 'success'
  | 'warn'
  | 'error'
  | 'info'
  | 'custom'
  | 'initiating'

type Size = `normal` | `small` | 'custom'

const classNameBuilder = getClassNameBuilder()

interface BadgeProps {
  color?: BadgeColor
  size?: Size
  className?: string
  children: ReactNode
  style?: React.CSSProperties
  title?: HTMLDivElement['title']
  icon?: IconName
  loading?: boolean
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  color,
  size,
  icon,
  loading = false,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        classNameBuilder({
          color,
          size,
        }),
        className,
      )}
      {...props}
    >
      {loading ? (
        <Spinner size="extra-small" className="mr-1" />
      ) : (
        icon && <Icon name={icon} size="extra-small" className="mr-1" />
      )}

      {children}
    </div>
  )
}

function getClassNameBuilder() {
  return cva(
    [
      `inline-flex items-center border text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2`,
    ],
    {
      variants: {
        color: {
          normal: `text-primary-foreground bg-primary border-primary`,
          inverted:
            'bg-white border-neutral-300 text-neutral-950 dark:bg-black dark:text-neutral-100',
          gray: `text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-dark-700`,
          success: `bg-green-100 dark:bg-green-500/10 border-green-400 text-green-600 dark:text-green-600`,
          warn: `bg-orange-50 dark:bg-orange-100/10 border-orange-300 text-orange-600`,
          error: `bg-rose-50 dark:bg-rose-500/10 border-rose-300 text-rose-600 dark:text-rose-600`,
          info: `bg-cyan-50 dark:bg-cyan-500/10 border-cyan-300 text-cyan-600 dark:text-cyan-600`,
          initiating: `bg-indigo-50 dark:bg-indigo-500/10 border-indigo-300 text-indigo-600 dark:text-sky-600`,
          custom: '',
        },
        size: {
          normal: `rounded-lg px-3 py-[7px] text-sm`, // 7px and
          small: `rounded-full px-2 py-[3px] text-xs`, // 3px is intentionally 1px less because of 1px border
          custom: '',
        },
      },
      defaultVariants: {
        color: `normal`,
        size: `normal`,
      },
    },
  )
}
