import type { ReactNode } from 'react'

import { cva } from 'cva'

import { cn } from '../utils'

type HeadingType = 1 | 2 | 3 | 4 | 5 | 6
type HeadingSize = 'extra-small' | 'small' | 'medium' | 'large'
const Heading: React.FC<{
  type?: HeadingType
  size: HeadingSize
  className?: string
  children: ReactNode
}> = ({ type, size, children, className }) => {
  const classNameBuilder = getClassNameBuilder()

  const classNames = cn(classNameBuilder({ size }), className)
  if (type) {
    switch (type) {
      case 1:
        return <h1 className={classNames}>{children}</h1>
      case 2:
        return <h2 className={classNames}>{children}</h2>
      case 3:
        return <h3 className={classNames}>{children}</h3>
      case 4:
        return <h4 className={classNames}>{children}</h4>
      case 5:
        return <h5 className={classNames}>{children}</h5>
      case 6:
        return <h6 className={classNames}>{children}</h6>
    }
  }

  switch (size) {
    case 'extra-small':
      return <h4 className={classNames}>{children}</h4>
    case 'small':
      return <h3 className={classNames}>{children}</h3>
    case 'medium':
      return <h2 className={classNames}>{children}</h2>
    case 'large':
      return <h1 className={classNames}>{children}</h1>
  }
}
Heading.displayName = 'Heading'

function getClassNameBuilder() {
  return cva(``, {
    variants: {
      size: {
        'extra-small': `text-sm font-semibold`,
        small: `text-xl font-semibold`,
        medium: `text-2xl font-bold`,
        large: `text-4xl font-extrabold`,
      },
    },
    defaultVariants: {
      size: `large`,
    },
  })
}

export { Heading }
