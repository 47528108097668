'use client'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'

import { cn } from '../utils'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
    layout?: 'fill' | 'start'
  }
>(({ className, layout = 'start', ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'h-9 inline-flex text-sm font-medium text-muted-foreground flex-1 border-b-[0.5px] border-b-neutral-300 w-full overflow-x-auto',
      // 'inline-flex h-10 items-center justify-center bg-muted p-1 text-foreground border-b',
      layout === 'fill' && 'grid grid-cols-2',
      className,
    )}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'h-full px-6 flex mt-[0.5px] pb-0.5 whitespace-nowrap items-center justify-center',
      'data-[state=active]:text-foreground data-[state=active]:pb-0 data-[state=active]:border-b-2 data-[state=active]:border-b-primary',
      className,
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      // 'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
