'use client'

import type { ReactNode } from 'react'

import { Card, Checkbox, Icon } from './index'

type ExpandBoxProps = {
  open: boolean
  title: ReactNode
  additionalInfo?: ReactNode
  showChevron?: boolean
  showCheckbox?: boolean
  checked?: boolean
  onToggleOpen?: (currentState: boolean) => void
}
export function ExpandBox({
  open,
  title,
  additionalInfo,
  showCheckbox,
  showChevron = true,
  onToggleOpen,
  checked = true,
  children,
}: React.PropsWithChildren<ExpandBoxProps>) {
  const handleClick = () => {
    if (onToggleOpen) {
      onToggleOpen(!open)
    }
  }

  return (
    <Card className={'p-4 w-full'}>
      <div className="flex items-center gap-4 w-full">
        <button
          onClick={handleClick}
          className={'flex items-center gap-4 grow'}
        >
          {showChevron && (
            <div>
              <Icon name={open ? 'ChevronDown' : 'ChevronRight'} size="small" />
            </div>
          )}

          <div className="grow font-medium text-sm text-left leading-6">
            {title}
          </div>
        </button>

        <div>{additionalInfo}</div>
        {showCheckbox && (
          <Checkbox className="ml-auto" checked={checked} interactive={false} />
        )}
      </div>
      {open && (
        <div className="border-neutral-300 border-t-[0.5px] mt-4 pt-4">
          {children}
        </div>
      )}
    </Card>
  )
}
