'use client'

import * as AvatarPrimivite from '@radix-ui/react-avatar'
import classNames from 'clsx'
import * as React from 'react'

function stringToHslColor(
  inputStr: string,
  saturation: number,
  lightness: number,
) {
  let hash = 0
  for (let i = 0; i < inputStr.length; i++) {
    hash = inputStr.charCodeAt(i) + ((hash << 5) - hash)
  }
  const h = hash % 360
  const saturationRandomness = Math.abs(hash % Math.ceil(saturation / 2))
  const s = Math.floor(saturation / 2 + saturationRandomness)
  return 'hsl(' + h + ', ' + s + '%, ' + lightness + '%)'
}

export type AvatarSize = 'small' | 'medium'

const AVATAR_LIGHTNESS = 55
const AVATAR_SATURATION = 60
const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimivite.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimivite.Root> & {
    size?: AvatarSize
  }
>(({ className, size = 'small', ...props }, ref) => (
  <AvatarPrimivite.Root
    ref={ref}
    className={classNames(
      'relative flex shrink-0 overflow-hidden rounded-full',
      size === 'small' ? 'h-6 w-6' : 'h-8 w-8',
      className,
    )}
    {...props}
  />
))

Avatar.displayName = AvatarPrimivite.Root.displayName

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimivite.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimivite.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimivite.Image
    ref={ref}
    className={classNames('aspect-square h-full w-full', className)}
    {...props}
  />
))

AvatarImage.displayName = AvatarPrimivite.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimivite.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimivite.Fallback> & {
    name: string
    size?: AvatarSize
  }
>(({ className, name, size = 'small', ...props }, ref) => (
  <AvatarPrimivite.Fallback
    ref={ref}
    className={classNames(
      `flex h-full w-full items-center justify-center rounded-full uppercase text-white font-semibold`,
      size === 'small' ? 'text-xs' : 'text-base',
      className,
    )}
    style={{
      backgroundColor: stringToHslColor(
        name,
        AVATAR_SATURATION,
        AVATAR_LIGHTNESS,
      ),
    }}
    {...props}
  >
    {name[0] || ''}
  </AvatarPrimivite.Fallback>
))

AvatarFallback.displayName = AvatarPrimivite.Fallback.displayName

export { Avatar, AvatarImage, AvatarFallback }
