import type React from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { clsx as classNames } from 'clsx'

import { Badge } from './Badge'

type IconType = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
    title?: string
    titleId?: string
  } & React.RefAttributes<SVGSVGElement>
>

interface ErrorBadgeProps {
  statusCode: string
  icon?: IconType
  className?: string
}

export const ErrorBadge: React.FC<ErrorBadgeProps> = ({
  statusCode,
  className,
}) => {
  return (
    <Badge
      size="custom"
      color="normal"
      className={classNames('py-4 px-5 rounded-4xl border-0', className)}
    >
      <ExclamationTriangleIcon className="h-12 w-12" />
      <span className="ml-2.5 text-5xl font-bold ml-1 capitalize">
        {statusCode}
      </span>
    </Badge>
  )
}
