'use client'

import type { IconProps } from './Icon'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import classNames from 'clsx'
import * as React from 'react'

import { Icon } from '.'

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuGroup = DropdownMenuPrimitive.Group

const DropdownMenuPortal = DropdownMenuPrimitive.Portal

const DropdownMenuSub = DropdownMenuPrimitive.Sub

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean
    clickable?: boolean
  }
>(({ className, inset, clickable = true, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={classNames(
      'flex cursor-default select-none items-center space-x-1.5 rounded-md' +
        ' w-full justify-between text-sm font-medium outline-none h-11 px-3',
      inset && 'pl-8',
      clickable &&
        `cursor-pointer transition-colors focus:bg-primary-50 active:bg-primary-100 dark:focus:bg-dark-600 dark:active:bg-dark-900`,
      className,
    )}
    {...props}
  >
    <div>{children}</div>
    <Icon name="ChevronRight" size="small" className="ml-auto" />
  </DropdownMenuPrimitive.SubTrigger>
))

DropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName

const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={classNames(
      'animate-in slide-in-from-left-1 z-50 min-w-[8rem] overflow-hidden' +
        ' rounded-md border border-gray-100 bg-white p-1 shadow-md' +
        ' dark:border-dark-700 dark:bg-dark-800',
      className,
    )}
    {...props}
  />
))

DropdownMenuSubContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, alignOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      alignOffset={alignOffset}
      className={classNames(
        'animate-in data-[side=bottom]:slide-in-from-top-2 w-auto' +
          ' data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[10rem]' +
          ' overflow-hidden border-[0.5px] border-neutral-300' +
          ' bg-white shadow-dropdown dark:border-dark-600 dark:bg-dark-800' +
          ' dark:shadow-[0_4px_8px_0] dark:shadow-primary-500/30' +
          ' dark:text-gray-300 md:rounded-md',
        className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
))

DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean
    clickable?: boolean
  }
>(({ className, inset, clickable = true, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={classNames(
      'relative flex h-11 w-full items-center focus:outline-none' +
        ' select-none px-3 text-sm font-medium outline-none' +
        ' data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      {
        'pl-8': inset,
        [`transition-colors duration-300 cursor-pointer focus:bg-primary-50 active:bg-primary-100 dark:focus:bg-dark-700 dark:active:bg-dark-600`]:
          clickable,
      },
      className,
    )}
    {...props}
  />
))

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={classNames(
      'px-2 py-1.5  text-neutral-950 text-sm font-semibold leading-tight  dark:text-gray-400',
      inset && 'pl-8',
      className,
    )}
    {...props}
  />
))

DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={classNames('h-px bg-gray-100 dark:bg-dark-600', className)}
    {...props}
  />
))

DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

const DropdownMenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={classNames('ml-auto text-xs tracking-widest', className)}
      {...props}
    />
  )
}

DropdownMenuShortcut.displayName = 'DropdownMenuShortcut'

const DropdownMenuIcon = ({ name }: IconProps) => {
  return (
    <div className="flex items-center justify-center w-5 h-5 mr-1.5">
      <Icon name={name} size="small" />
    </div>
  )
}

DropdownMenuIcon.displayName = 'DropdownMenuIcon'

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuIcon,
}
