import type React from 'react'

import { forwardRef } from 'react'

import { Icon } from './Icon'

export const SearchInput = forwardRef<
  React.ElementRef<'input'>,
  {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    value: string
    showKeyboardShortcut?: boolean
    placeholder?: string
    disabled?: boolean
  }
>(function SearchInputComponent(
  {
    value,
    onChange,
    placeholder = 'Search',
    showKeyboardShortcut = false,
    ...rest
  },
  ref,
) {
  return (
    <div className="w-full px-3 py-2.5 bg-white dark:bg-background rounded-lg border-[0.5px] border-neutral-300 dark:border-dark-500 justify-start items-center gap-2 inline-flex relative">
      <Icon name="MagnifyingGlass" size="small" />
      <input
        type="text"
        value={value}
        ref={ref}
        onChange={onChange}
        className="grow shrink basis-0 text-neutral-950 placeholder-neutral-400 text-sm font-medium outline-none bg-transparent"
        placeholder={placeholder}
        {...rest}
      />
      {showKeyboardShortcut ? (
        <div className="absolute right-2 top-1/2 -translate-y-1/2 text-neutral-400 text-sm text-medium">
          ⌘ /
        </div>
      ) : null}
    </div>
  )
})
