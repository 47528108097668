import { Inter as SansFont } from 'next/font/google'

const sans = SansFont({
  subsets: ['latin'],
  variable: '--font-family-sans',
  fallback: [
    'ui-sans-serif',
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji',
  ],
  preload: true,
  weight: 'variable',
})

export const fontsClassName = `${sans.className} ${sans.variable}`
