import React from 'react'

import { cn } from '../utils'

import { Separator } from './Separator'

interface CardProps {
  isSelected?: boolean
  size?: 'large' | 'medium' | 'small'
}

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CardProps
>(({ className, isSelected, size = 'medium', ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'bg-card dark:bg-dark-900 text-card-foreground border-[0.5px] border-neutral-300 p-5 space-y-5 dark:border-dark-700',
      isSelected && 'border-neutral-950 border',
      size === 'large' && 'rounded-4xl p-5',
      size === 'medium' && 'rounded-2xl p-5',
      size === 'small' && 'rounded-2xl p-3',
      props.onClick && 'cursor-pointer hover:border-neutral-950',
      className,
    )}
    {...props}
  />
))
Card.displayName = 'Card'

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col space-y-1.5', className)}
    {...props}
  />
))
CardHeader.displayName = 'CardHeader'

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'text-2xl font-semibold leading-none tracking-tight',
      className,
    )}
    {...props}
  />
))
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
))
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...restProps }, ref) => (
  <div ref={ref} className={className} {...restProps} />
))
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ children, ...props }, ref) => (
  <div ref={ref} {...props}>
    <Separator className="mb-5" />
    {children}
  </div>
))
CardFooter.displayName = 'CardFooter'

export { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle }
