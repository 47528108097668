'use client'

import * as SeparatorPrimitive from '@radix-ui/react-separator'
import * as React from 'react'

import { cn } from '../utils'

import { Text } from './Text'

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & {
    label?: React.ReactNode
  }
>(
  (
    {
      className,
      orientation = 'horizontal',
      decorative = true,
      label,
      ...props
    },
    ref,
  ) => {
    return label && orientation === 'horizontal' ? (
      <div className="flex flex-row w-full gap-2 items-center" ref={ref}>
        <SeparatorPrimitive.Root
          decorative={decorative}
          orientation={orientation}
          className={cn(
            'shrink bg-border dark:bg-dark-700 h-[0.5px] w-full base-1',
            className,
          )}
          {...props}
        />
        <Text size="small" className="grow whitespace-nowrap">
          {label}
        </Text>
        <SeparatorPrimitive.Root
          decorative={decorative}
          orientation={orientation}
          className={cn(
            'shrink bg-border dark:bg-dark-700 h-[0.5px] w-full base-1',
            className,
          )}
          {...props}
        />
      </div>
    ) : (
      <SeparatorPrimitive.Root
        ref={ref}
        decorative={decorative}
        orientation={orientation}
        className={cn(
          'shrink-0 bg-border dark:bg-dark-700',
          orientation === 'horizontal'
            ? 'h-[0.5px] w-full'
            : 'self-stretch w-[0.5px]',
          className,
        )}
        {...props}
      />
    )
  },
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
