'use client'

import type React from 'react'

import classNames from 'clsx'
import { forwardRef } from 'react'

import { INPUT_CLASS_NAMES } from '../lib/inputClassNames'

import { Label } from './Label'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean
}

const Hint: React.FC<React.PropsWithChildren<{ id?: string }>> = ({
  children,
  id,
}) => {
  return (
    <span
      id={id}
      className={`block mt-1 pl-1 text-xs
        font-normal leading-tight text-neutral-400 dark:text-gray-400`}
    >
      {children}
    </span>
  )
}

const Input = forwardRef<React.ElementRef<'input'>, InputProps>(
  function TextFieldInputComponent(
    { className, children, hasError, ...props },
    ref,
  ) {
    return (
      <div
        className={classNames(
          INPUT_CLASS_NAMES,
          `flex items-center h-10`,
          className,
          {
            [`cursor-not-allowed bg-neutral-100 hover:bg-neutral-100 hover:border-neutral-300 dark:bg-dark-800`]:
              props.disabled,
          },
          { '!border-pink-500': hasError },
        )}
      >
        {children && <span className={'flex pl-2.5'}>{children}</span>}

        <input
          {...props}
          className={classNames(
            `flex-1 rounded-md bg-transparent outline-none sm:font-base`,
          )}
          ref={ref}
        />
      </div>
    )
  },
)

type TextFieldComponent = React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> & {
  Label: typeof Label
  Hint: typeof Hint
  Input: typeof Input
  Error: typeof ErrorMessage
}

const TextField: TextFieldComponent = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(`flex flex-col space-y-1`, className)} {...rest}>
      {children}
    </div>
  )
}

const ErrorMessage: React.FC<
  { error?: string } & React.HTMLAttributes<unknown>
> = ({ error, ...props }) => {
  const shouldDisplay = !!error

  if (!shouldDisplay) {
    return null
  }

  return (
    <Hint>
      <span {...props} className={'py-0.5 text-pink-500 dark:text-pink-500'}>
        {error}
      </span>
    </Hint>
  )
}

TextField.Hint = Hint
TextField.Label = Label
TextField.Input = Input
TextField.Error = ErrorMessage

export {
  TextField,
  Hint as TextFieldHint,
  Label as TextFieldLabel,
  Input as TextFieldInput,
  ErrorMessage as TextFieldError,
}
