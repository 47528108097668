'use client'
import type { CalendarProps } from './'

import { format } from 'date-fns'
import { forwardRef } from 'react'

import { cn } from '../utils'

import {
  Calendar,
  Icon,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from './'

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date
    setDate: (date?: Date) => void
    calendarProps?: CalendarProps
  }
>(function DatePickerCmp({ date, setDate, calendarProps }, ref) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !date && 'text-muted-foreground',
          )}
        >
          <Icon name="Calendar" size="small" className="mr-2" />
          {date ? format(date, 'PPP') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" ref={ref}>
        <Calendar
          {...calendarProps}
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
})
