import type { DateLike } from '../utils/formatDistanceToNow'

import { parseISO, format } from 'date-fns'

const formatTypes = {
  date: 'yyyy-MM-dd',
  minutes: 'yyyy-MM-dd HH:mm',
  seconds: 'yyyy-MM-dd HH:mm:ss',
}

export const FormattedDate = ({
  date,
  format: formatType,
}: {
  date: DateLike | undefined | null
  format: 'date' | 'minutes' | 'seconds'
}) => {
  if (!date) {
    return <span>{'N/A'}</span>
  }

  const formatStr = formatTypes[formatType as keyof typeof formatTypes]
  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date)

  const outputDateString = format(parsedDate, 'yyyy-MM-dd HH:mm:ss')
  const visualDateString = format(parsedDate, formatStr)

  return (
    <time
      dateTime={outputDateString} // YYYY-MM-DD hh:mm:ss - html attribute
      title={outputDateString} // Shown on hover
      // https://nextjs.org/docs/messages/react-hydration-error
      suppressHydrationWarning
    >
      {visualDateString}
    </time>
  )
}
