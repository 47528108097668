import type { TestClassification } from 'types/src/run-test-case'

import { runTestCaseConfig } from '../lib/runTestCaseConfig'

import { ThinBadge } from './ThinBadge'
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip'

export interface ClassificationBadgeProps {
  classification: TestClassification
}

export function ClassificationBadge({
  classification,
}: ClassificationBadgeProps) {
  return (
    <ThinBadge colorClasses={runTestCaseConfig[classification].colorClasses}>
      {classification === 'POSITIVE' ? (
        runTestCaseConfig[classification].label
      ) : (
        <Tooltip delayDuration={200}>
          <TooltipTrigger>
            {runTestCaseConfig[classification].label}
          </TooltipTrigger>
          <TooltipContent>
            <div className="w-80">
              Tests gets a classification based on if the expected result is to
              fulfil the objective of the test or not.
              <br />
              For negative test cases the objective is expected to not be
              fulfilled, for example it should not be possible to sign in with
              an incorrect password.
            </div>
          </TooltipContent>
        </Tooltip>
      )}
    </ThinBadge>
  )
}
