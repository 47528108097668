export const runResultConfig = {
  FAILED: {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'Failed',
  },
  PASSED: {
    colorClasses: {
      bg: 'bg-green-500',
      text: 'text-green-500',
    },
    label: 'Passed',
  },
  SKIPPED: {
    colorClasses: {
      bg: 'bg-sky-300',
      text: 'text-sky-300',
    },
    label: 'Skipped',
  },
}
