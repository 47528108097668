import type { IconName, IconProps } from './Icon'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'cva'
import * as React from 'react'

import { cn } from '../utils'

import { Icon } from './Icon'
import { Spinner } from './Spinner'

export const buttonVariants = cva(
  'inline-flex items-center whitespace-nowrap justify-center rounded-lg text-sm font-semibold ring-offset-background transition-colors cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none border-0',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:bg-primary/50 disabled:bg-neutral-300',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/60',
        outline:
          'border border-neutral-950 hover:border-neutral-500 bg-transparent dark:border-neutral-300 hover:text-dark-700 disabled:text-neutral-300 disabled:border-neutral-300 dark:disabled:border-neutral-400 dark:disabled:text-neutral-400',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'disabled:text-dark-500',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      disabled: {
        true: '',
        false: '',
      },

      size: {
        medium: 'h-10 px-3 py-2',
        small: 'h-8 rounded-lg px-3 text-xs',
        large: 'h-10 rounded-lg px-3',
        icon: 'h-10 w-10',
        block: 'w-full h-10 px-3 py-2',
      },
    },
    compoundVariants: [
      {
        variant: 'default',
        disabled: true,
        className: 'bg-primary/20 hover:bg-primary/20',
      },
      {
        variant: 'destructive',
        disabled: true,
        className: 'bg-destructive/40 hover:bg-destructive/40',
      },
      {
        variant: 'outline',
        disabled: true,
        className:
          'border-neutral-400 hover:border-neutral-400 text-neutral-400 hover:text-neutral-400',
      },
      {
        variant: 'ghost',
        disabled: false,
        className:
          'ring-transparent hover:bg-neutral-200 active:bg-gray-200 dark:bg-dark-900 dark:text-gray-300 dark:hover:bg-dark-800 dark:hover:text-white dark:active:bg-dark-700 dark:active:bg-dark-700',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean
  disabled?: boolean
  asChild?: boolean
  icon?: IconName
  iconPosition?: 'left' | 'right'
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'default',
      size,
      loading = false,
      disabled = false,
      children,
      asChild = false,
      icon,
      iconPosition = 'left',
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    const marginClassName = !children
      ? 'shrink-0'
      : iconPosition === 'left'
        ? 'shrink-0 mr-2'
        : 'shrink-0 ml-2'

    const iconOrSpinner = loading ? (
      <Spinner size="small" className={cn('text-current', marginClassName)} />
    ) : (
      icon && (
        <Icon
          name={icon}
          size={getIconSize(size)}
          className={marginClassName}
        />
      )
    )

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, disabled }), className)}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {iconPosition === 'left' && iconOrSpinner}
        {children}
        {iconPosition === 'right' && iconOrSpinner}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

const getIconSize = (buttonSize: ButtonProps['size']): IconProps['size'] => {
  switch (buttonSize) {
    case 'icon':
    case 'block':
      return 'large'
    default:
      return buttonSize || 'large'
  }
}
