import { cn } from '../utils'

import { Button } from './Button'

export const Pagination: React.FC<{
  pageCount: number
  currentPage: number
  onSetPage: (page: number) => void
}> = ({ pageCount, currentPage, onSetPage }) => {
  const renderNumberedButtons = () => {
    const buttons = []

    for (
      let i = Math.max(0, currentPage - 3);
      i <= Math.min(currentPage + 3, pageCount - 1);
      i++
    ) {
      buttons.push(
        <Button
          key={i}
          disabled={i === currentPage}
          variant="ghost"
          className={cn('h-6 w-7', {
            'bg-neutral-200 !text-primary dark:bg-dark-800': i === currentPage,
          })}
          onClick={() => onSetPage(i)}
        >
          {i + 1}
        </Button>,
      )
    }

    return buttons
  }

  return (
    <div className="flex items-center justify-center gap-2 w-full">
      <Button
        variant="ghost"
        size="icon"
        icon="ChevronDoubleLeft"
        onClick={() => onSetPage(0)}
        disabled={currentPage === 0}
        className="h-6 w-6"
      />
      <Button
        variant="ghost"
        size="icon"
        icon="ChevronLeft"
        onClick={() => onSetPage(currentPage - 1)}
        disabled={currentPage === 0}
        className="h-6 w-7"
      />
      {renderNumberedButtons()}
      <Button
        variant="ghost"
        size="icon"
        icon="ChevronRight"
        onClick={() => onSetPage(currentPage + 1)}
        disabled={pageCount <= currentPage + 1}
        className="h-6 w-7"
      />
      <Button
        variant="ghost"
        size="icon"
        icon="ChevronDoubleRight"
        onClick={() => onSetPage(pageCount - 1)}
        disabled={pageCount <= currentPage + 1}
        className="h-6 w-7"
      />
    </div>
  )
}
Pagination.displayName = 'Pagination'
