export const runStatusConfig = {
  INITIATED: {
    colorClasses: {
      bg: 'bg-violet-300',
      text: 'text-current',
    },
    label: 'Pending',
  },
  RUNNING: {
    colorClasses: {
      bg: 'bg-neutral-300',
      text: 'text-current',
    },
    label: 'Running',
  },
  COMPLETED: {
    colorClasses: {
      bg: 'bg-green-500',
      text: 'text-green-500',
    },
    label: 'Completed',
  },
  ERROR: {
    colorClasses: {
      bg: 'bg-pink-500',
      text: 'text-pink-500',
    },
    label: 'Error',
  },
}
