'use client'

import { useId } from 'react'

import { CopyButton } from './CopyButton'

import { TextField, Label } from '.'
type CopyInputProps = {
  label?: string
  value: string
  disableCopy?: boolean
}
export const TextCopyField: React.FC<CopyInputProps> = ({
  label,
  value,
  disableCopy,
}) => {
  const id = useId()
  return (
    <>
      {label && <Label htmlFor={id}>{label}</Label>}
      <div className="py-2 flex space-x-2">
        <TextField.Input
          id={id}
          value={value}
          disabled={true}
          className="!bg-background"
        />
        <CopyButton
          value={value}
          className="border-input"
          disabled={disableCopy}
        />
      </div>
    </>
  )
}
