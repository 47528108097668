import type { SpinnerSize } from './Spinner'
import type { ReactNode } from 'react'

import { cn } from '../utils'

import { Spinner } from './Spinner'

export const Loader = ({
  loading,
  children,
  size,
  className,
}: {
  loading: boolean
  children: ReactNode
  size?: SpinnerSize
  className?: string
}) =>
  loading ? (
    <div className={cn('flex items-center justify-center', className)}>
      <Spinner size={size} />
    </div>
  ) : (
    children
  )
