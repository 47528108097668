'use client'

import type { ImageLoader } from 'next/dist/shared/lib/get-img-props'
import type React from 'react'
import type {
  FormEvent,
  MouseEventHandler,
  ReactNode,
  Ref,
  RefAttributes,
} from 'react'
import type {
  Control,
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form'

import Image from 'next/image'
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useController } from 'react-hook-form'

import { useForkRef } from '../hooks/useForkRef'
import { cn } from '../utils'

import { Button, Icon, Label, Text, useToast } from './'

type ImageUploadFieldProps = {
  image?: string | null
  imageLoader?: ImageLoader
  children?: React.ReactNode
  alt?: string
}

export type ImageUploadFieldElementProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = File | string | null,
> = Omit<ImageUploadFieldProps, 'name'> & {
  rules?: UseControllerProps<TFieldValues, TName>['rules']
  name: TName
  parseError?: (error: FieldError) => ReactNode
  control: Control<TFieldValues>
}

type ImageUploadFieldElementComponent = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = File | string | null,
>(
  props: ImageUploadFieldElementProps<TFieldValues, TName, TValue> &
    RefAttributes<HTMLInputElement>,
) => JSX.Element

const IMAGE_SIZE = 92

const activeClass = 'bg-neutral-200'

const fileIsImage = (file: File) => {
  //SVG, PNG, JPG or GIF

  const allowedTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/gif']

  if (allowedTypes.includes(file.type)) {
    return true
  }

  return false
}

function ImageUploadFieldElement<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue = unknown,
>(props: ImageUploadFieldElementProps<TFieldValues, TName, TValue>) {
  const { toast } = useToast()
  const { image, imageLoader, name, children, control, alt } = props

  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields, defaultValues },
  } = useController({
    name,
    control,
  })

  const localRef = useRef<HTMLInputElement>(null)

  const containerRef = useRef<HTMLLabelElement>(null)
  const [showDropMessage, setShowDropMessage] = useState(false)

  const initialState = useMemo(
    () => ({
      image,
      fileName: '',
      file: null,
    }),
    [image],
  )
  const [state, setState] = useState<{
    image?: string | null
    fileName?: string
    file?: File | null
  }>(initialState)

  const shakeContainer = () => {
    containerRef.current?.classList.add('animate-shake')

    setTimeout(() => {
      containerRef.current?.classList.remove('animate-shake')
    }, 1000)
  }

  const handleFiles = (files: FileList | null) => {
    if (!files?.length) {
      return
    }
    const file = files[0]

    if (!fileIsImage(file)) {
      const description = `Please select a SVG, PNG, JPG or GIF`

      toast({
        title: `Invalid file type ${file.type}`,
        description,
        variant: 'warning',
      })

      shakeContainer()
      setState(initialState)
      field.onChange(initialState.image)

      return
    }
    const imageObjectUrl = URL.createObjectURL(file)

    setState({
      image: imageObjectUrl,
      fileName: file.name,
      file,
    })

    field.onChange(file)
  }

  const onInputChange = (e: FormEvent<HTMLInputElement>) => {
    e.persist()
    handleFiles(e.currentTarget.files)
  }

  const onDragEnter = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault()
    if (evt.dataTransfer?.files) {
      setShowDropMessage(true)
    }
  }

  const onDragOver = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault()
    if (evt.dataTransfer?.files) {
      containerRef.current?.classList.add(activeClass)
      setShowDropMessage(true)
    }
  }

  const onDragEnd = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault()
    setShowDropMessage(false)
    containerRef.current?.classList.remove(activeClass)
  }

  const onDragLeave = (evt: React.DragEvent<HTMLLabelElement>) => {
    setShowDropMessage(false)
    containerRef.current?.classList.remove(activeClass)
  }

  const onDrop = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault()
    if (localRef.current && evt.dataTransfer?.files) {
      setShowDropMessage(false)
      containerRef.current?.classList.remove(activeClass)
      handleFiles(evt.dataTransfer.files)
    }
  }

  const removeImage: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      setState({
        image: '',
        fileName: '',
      })

      field.onChange('')
    },
    [field],
  )

  return (
    <label
      htmlFor={'image-upload-input'}
      ref={containerRef}
      tabIndex={0}
      className={`
        relative flex justify-center h-[132px] items-center flex-col cursor-pointer rounded-2xl bg-neutral-100 p-5 outline-none ring-offset-1 transition-all hover:bg-neutral-200 focus:ring-2
        focus:ring-primary-200 dark:border-dark-600 dark:bg-neutral-100/10 dark:hover:border-dark-500 dark:focus:ring-primary-500/70 dark:focus:ring-offset-dark-800`}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          localRef.current?.click()
        }
      }}
    >
      <input
        ref={localRef}
        className={'hidden'}
        type={'file'}
        onInput={onInputChange}
        accept="image/*"
        id={'image-upload-input'}
      />

      <div className={'flex items-center space-x-4 w-full overflow-hidden'}>
        {state.image ? (
          <div className={'flex items-center min-w-20'}>
            <Image
              loader={imageLoader}
              loading={'lazy'}
              style={{
                width: IMAGE_SIZE,
                height: IMAGE_SIZE,
              }}
              className={'object-contain'}
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              src={state.image as string}
              alt={alt ?? ''}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center w-full">
            <div
              className={cn(
                'rounded-xl relative p-2 bg-white dark:bg-background mb-3 transition-all',
                { 'bg-transparent dark:bg-transparent': showDropMessage },
              )}
            >
              <Icon
                size="large"
                name="CloudArrowUp"
                className={cn('transition-all', {
                  'w-10 h-10 text-neutral-600 rotate-360 animate-bounce':
                    showDropMessage,
                })}
              />
            </div>
            {showDropMessage ? (
              <Text className="font-normal text-zinc-500">
                Drop image here...
              </Text>
            ) : (
              <>
                <Text>
                  <span className="font-semibold">Click to upload</span>{' '}
                  <span className="font-normal text-zinc-500">
                    or drag and drop
                  </span>
                </Text>
                <Text className="font-normal  text-zinc-500">
                  SVG, PNG, JPG or GIF
                </Text>
              </>
            )}
          </div>
        )}

        {state.image && (
          <div className={'flex flex-auto flex-shrink truncate'}>
            {state.fileName ? (
              <Label asChild className={'truncate text-xs'}>
                <span>{state.fileName}</span>
              </Label>
            ) : (
              <Label asChild className={'cursor-pointer truncate text-xs'}>
                <span>{children}</span>
              </Label>
            )}
          </div>
        )}

        {state.image && (
          <Button
            variant="ghost"
            size="icon"
            onClick={removeImage}
            type="button" // IMPORTANT to prevent triggering this button when clicking enter on an input
            icon="XMark"
          />
        )}
      </div>
    </label>
  )
}

ImageUploadFieldElement.displayName = 'ImageUploadField'

export const ImageUploadField = ImageUploadFieldElement
