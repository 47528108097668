'use client'

import type { ButtonProps } from './Button'
import type React from 'react'

import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Button } from './Button'

export const CopyButton: React.FC<{
  value: string
  disabled?: boolean
  className?: string
  size?: ButtonProps['size']
}> = ({ value, className, disabled, size = 'medium' }) => {
  const [copied, setCopied] = useState(false)
  const onCopy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      <Button
        variant="outline"
        type="button"
        icon={copied ? 'Check' : 'Clipboard'}
        size={size}
        className={className}
        title="Copy"
        disabled={disabled}
      />
    </CopyToClipboard>
  )
}
CopyButton.displayName = ''
