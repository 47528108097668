import {
  format,
  differenceInMinutes,
  isAfter,
  differenceInSeconds,
  differenceInHours,
  differenceInDays,
  startOfYesterday,
  differenceInWeeks,
  parseISO,
} from 'date-fns'

export type DateLike = Date | number | string

export const formatDistanceToNow = (date: DateLike): string => {
  const now = new Date()
  const then = typeof date === 'string' ? parseISO(date) : new Date(date)

  if (isAfter(then, now)) {
    return '-'
  }

  if (Math.abs(differenceInSeconds(then, now)) < 15) {
    return 'Just now'
  }

  if (Math.abs(differenceInMinutes(then, now)) < 1) {
    return 'A minute ago'
  }

  if (Math.abs(differenceInHours(then, now)) < 1) {
    const minutes = Math.abs(differenceInMinutes(then, now))
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`
  }

  if (Math.abs(differenceInDays(then, now)) < 1) {
    const hours = Math.abs(differenceInHours(then, now))
    return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`
  }

  if (isAfter(then, startOfYesterday())) {
    return 'Yesterday'
  }

  if (Math.abs(differenceInWeeks(then, now)) < 1) {
    const days = Math.abs(differenceInDays(then, now))
    return days === 1 ? `${days} day ago` : `${days} days ago`
  }

  if (Math.abs(differenceInDays(then, now)) === 7) {
    return '1 week ago'
  }

  return format(then, 'MMMM d, yyyy')
}
