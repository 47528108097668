import type * as React from 'react'

import { cva, type VariantProps } from 'cva'
import { forwardRef } from 'react'

import { cn } from '../utils'

import { Icon } from './Icon'

const tooltipVariants = cva(
  'text-white rounded-lg inline-flex justify-start items-center gap-2 inline-flex max-w-xs leading-5',
  {
    variants: {
      variant: {
        default: 'bg-primary dark:text-neutral-950',
        error: 'bg-pink-600',
        warning: 'bg-orange-400',
        success: 'bg-green-500',
      },
      size: {
        md: 'px-3 py-3',
        sm: 'px-3 py-2',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  },
)

interface StaticTooltipProps
  extends React.BaseHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tooltipVariants> {}
export const StaticTooltip = forwardRef<HTMLDivElement, StaticTooltipProps>(
  ({ children, variant, size = 'md', title, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn([tooltipVariants({ variant, size, className })])}
      >
        {variant === 'error' && (
          <Icon name="XCircle" size="large" className="mr-1 relative" />
        )}
        {variant === 'success' && (
          <Icon name="CheckCircle" size="large" className="mr-1 relative" />
        )}
        {variant === 'warning' && (
          <Icon
            name="ExclamationTriangle"
            size="large"
            className="mr-1 relative"
          />
        )}
        <div>
          {title && <div className="font-semibold mb-0.5">{title}</div>}
          <div className="text-sm font-normal flex items-center flex-1">
            {children}
          </div>
        </div>
      </div>
    )
  },
)

StaticTooltip.displayName = 'StaticTooltip'
