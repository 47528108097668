import classNames from 'clsx'
import { forwardRef } from 'react'

export const FileField = forwardRef<
  React.ElementRef<'input'>,
  React.InputHTMLAttributes<unknown>
>(function InputFileComponent({ className, ...props }, ref) {
  return (
    <input
      {...props}
      ref={ref}
      type={'file'}
      className={classNames(
        `flex h-10 w-full cursor-pointer items-center justify-center
                border-transparent bg-transparent py-2 text-sm shadow-none
                file:disabled:hover:bg-primary disabled:cursor-default file:disabled:cursor-default
                file:mr-4 file:cursor-pointer
                file:rounded-lg file:border-0 file:bg-primary file:py-1
                file:px-4 file:text-xs file:font-semibold file:transition-colors
                file:text-white file:hover:bg-primary/50 file:active:bg-primary-700 dark:text-white dark:file:text-neutral-950`,
        className,
      )}
    />
  )
})
