import { cn } from '../utils'

export type SpinnerSize = 'extra-small' | 'small' | 'medium' | 'large'

const Dot = ({ size, turn }: { size: SpinnerSize; turn: number }) => (
  <div
    className={cn(
      'flex flex-col items-center',
      size === 'extra-small' && 'h-2.5',
      size === 'small' && 'h-4',
      size === 'medium' && 'h-8',
      size === 'large' && 'h-12',
    )}
    style={{
      transform: `rotate(${turn}turn)`,
    }}
  >
    <div
      className={cn(
        'rounded-full bg-current w-2 h-2 absolute animate-spinner-dot',
      )}
      style={{
        // Using a negative animation delay ensures the animation starts as if it's been animating in the background.
        // Otherwise, the first turn is going to look different from the rest of the rest.
        animationDelay: '-' + (1 - turn) + 's',
        width: {
          ['extra-small']: '2px',
          small: '3px',
          medium: '6px',
          large: '8px',
        }[size],
        height: {
          ['extra-small']: '2px',
          small: '3px',
          medium: '6px',
          large: '8px',
        }[size],
      }}
    />
  </div>
)

export const Spinner = ({
  className,
  size = 'medium',
}: {
  className?: string
  size?: SpinnerSize
}) => (
  <div
    role="status"
    className={cn(
      'relative flex justify-center items-center text-current dark:text-current',
      size === 'extra-small' && 'w-2.5 h-2.5',
      size === 'small' && 'w-4 h-4',
      size === 'medium' && 'w-8 h-8',
      size === 'large' && 'w-12 h-12',
      className,
    )}
  >
    <Dot size={size} turn={0} />
    <Dot size={size} turn={1 / 8} />
    <Dot size={size} turn={2 / 8} />
    <Dot size={size} turn={3 / 8} />
    <Dot size={size} turn={4 / 8} />
    <Dot size={size} turn={5 / 8} />
    <Dot size={size} turn={6 / 8} />
    <Dot size={size} turn={7 / 8} />
  </div>
)
