import type { ReactNode } from 'react'

import { cn } from '../utils'

import { Heading } from './Heading'

interface StepsTimelineProps {
  title?: string
  items: ReactNode[]
  className?: string
}

export const VerticalTimelineList = ({
  title,
  items,
  className,
}: StepsTimelineProps) => {
  const dotSize = 6
  const dotBase = `w-${dotSize} h-${dotSize}`
  const lineWrapBase = `w-${dotSize}`
  const lineClassName = 'bg-border dark:bg-border'

  return (
    <div className={cn('space-y-4', className)}>
      {title && <Heading size="small">{title}</Heading>}

      <ul className="flex flex-col items-center space-y-3 w-full" role="list">
        {items.map((item, index) => (
          <li key={index} className="w-full">
            <div className="flex items-center relative">
              <div
                className={cn(
                  dotBase,
                  `flex-shrink-0 bg-green-600 relative z-20 rounded-full border-white dark:border-current-background`,
                )}
                style={{
                  marginLeft: '-0.45rem',
                  borderWidth: '0.45rem',
                  borderStyle: 'solid',
                }}
              />
              {index !== items.length - 1 && (
                <div
                  className={cn(
                    lineWrapBase,
                    'absolute top-0 z-10 flex items-center jusitfy-center h-full',
                  )}
                >
                  <div
                    className={lineClassName}
                    style={{
                      height: 'calc(100% + 1rem)',
                      left: 'calc(50% - 0.5px - 0.45rem)',
                      position: 'absolute',
                      top: '1rem',
                      width: '1px',
                    }}
                  />
                </div>
              )}
              <p className="text-primary-700 dark:text-primary-500 ml-2">
                {item}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
