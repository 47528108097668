'use client'

import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import * as React from 'react'

import { cn } from '../utils'

import { Card, CardContent, CardHeader, CardTitle } from './Card'
import { Heading } from './Heading'

const HoverCard = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Root>
>(({ openDelay = 200, ...props }, ref) => (
  <HoverCardPrimitive.Root openDelay={openDelay} {...props} />
))
HoverCard.displayName = HoverCardPrimitive.Root.displayName

const HoverCardPortal = HoverCardPrimitive.Portal
HoverCardPortal.displayName = HoverCardPrimitive.Portal.displayName

const HoverCardTrigger = HoverCardPrimitive.Trigger
HoverCardTrigger.displayName = HoverCardPrimitive.Trigger.displayName

const HoverCardArrow = HoverCardPrimitive.Arrow
HoverCardArrow.displayName = HoverCardPrimitive.Arrow.displayName

const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content> & {
    title?: string
    contentClassName?: string
    cardClassName?: string
  }
>(
  (
    {
      title,
      className,
      cardClassName,
      contentClassName,
      align = 'center',
      sideOffset = 4,
      children,
      ...props
    },
    ref,
  ) => (
    <HoverCardPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-50 outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0' +
          'data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95' +
          'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2' +
          'data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    >
      <Card className={cn('shadow-dropdown p-0', cardClassName)}>
        {title ? (
          <CardHeader className="border-b-[0.5px] border-b-neutral-300 p-3">
            <CardTitle>
              <Heading size="extra-small">{title}</Heading>
            </CardTitle>
          </CardHeader>
        ) : null}
        <CardContent className={cn('p-3', contentClassName)}>
          {children}
        </CardContent>
      </Card>
    </HoverCardPrimitive.Content>
  ),
)
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName

export {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardArrow,
  HoverCardPortal,
}
