import type { DateLike } from '../utils/formatDistanceToNow'

import { sentenceCase } from 'change-case'
import { parseISO, format } from 'date-fns'

import { formatDistanceToNow } from '../utils/formatDistanceToNow'

import { Tooltip, TooltipTrigger, TooltipContent } from './Tooltip'

export const HumanRelativeDate = ({
  date,
  capitalize: shouldCapitalize = true,
}: {
  date: DateLike | undefined | null
  capitalize?: boolean
}) => {
  if (!date) {
    return <span>{'N/A'}</span>
  }

  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date)
  const outputDateString = format(parsedDate, 'yyyy-MM-dd HH:mm:ss')
  const formattedDate = formatDistanceToNow(parsedDate)

  return (
    <Tooltip
      content={outputDateString}
      delayDuration={500}
      // https://nextjs.org/docs/messages/react-hydration-error
      suppressHydrationWarning
    >
      <TooltipTrigger asChild>
        <time
          dateTime={outputDateString} // YYYY-MM-DD hh:mm:ss - html attribute
        >
          {shouldCapitalize ? sentenceCase(formattedDate) : formattedDate}
        </time>
      </TooltipTrigger>
      <TooltipContent>{outputDateString}</TooltipContent>
    </Tooltip>
  )
}
