export const runTestCaseConfig = {
  POSITIVE: {
    colorClasses: {
      bg: 'bg-sky-600',
      text: 'text-sky-600',
    },
    label: 'Positive',
  },
  NEGATIVE: {
    colorClasses: {
      bg: 'bg-pink-500',
      text: 'text-pink-500',
    },
    label: 'Negative',
  },
}
