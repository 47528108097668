import type Run from 'types/src/run'

import { runResultConfig, runStatusConfig } from '../lib'
import { cn } from '../utils'

export function RunResultStatusCircle({
  run,
  withLabel = false,
}: {
  run: Pick<Run, 'status' | 'result'>
  withLabel?: boolean
}) {
  let config =
    run.status === 'INITIATED'
      ? runStatusConfig.INITIATED
      : runStatusConfig.RUNNING

  if (run.result) config = runResultConfig[run.result]

  if (run.status === 'ERROR') config = runStatusConfig.ERROR

  const Circle = () => (
    <div
      className={cn(
        'w-2.5 h-2.5 relative bg-green-500 rounded-[10px]',
        config.colorClasses.bg,
        {
          'animate-pulse': run.status === 'RUNNING',
        },
      )}
    />
  )

  if (withLabel)
    return (
      <div className="justify-start items-center gap-1 inline-flex">
        <Circle />
        <div
          className={cn(
            config.colorClasses.text,
            'text-xs font-medium leading-tight dark:text-white',
          )}
        >
          {runStatusConfig[run.status].label}
        </div>
      </div>
    )

  return <Circle />
}
