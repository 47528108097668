import type { ReactNode } from 'react'

import { cva } from 'cva'

import { cn } from '../utils'

type TextSize = 'small' | 'medium' | 'large'
const Text: React.FC<{
  size?: TextSize
  className?: string
  children: ReactNode
}> = ({ size = 'medium', children, className }) => {
  const classNameBuilder = getClassNameBuilder()

  const classNames = cn(classNameBuilder({ size }), className)

  return <div className={classNames}>{children}</div>
}
Text.displayName = 'Text'

function getClassNameBuilder() {
  return cva([``], {
    variants: {
      size: {
        small: `text-xs text-neutral-500 dark:text-neutral-300`,
        medium: `text-sm font-medium`,
        large: `text-base font-medium`,
      },
    },
    defaultVariants: {
      size: `medium`,
    },
  })
}

export { Text }
