import type { ReactNode } from 'react'
import type React from 'react'

import { cn } from '../utils'

export const ThinBadge: React.FC<{
  colorClasses: {
    bg: string
    text: string
  }
  children?: ReactNode
}> = ({ children, colorClasses }) => {
  return (
    <div className="justify-start items-center gap-1 inline-flex whitespace-nowrap">
      <div
        className={cn('w-[3px] h-3 relative  rounded-full', colorClasses.bg)}
      />
      <div
        className={cn(' text-xs font-medium leading-none', colorClasses.text)}
      >
        {children}
      </div>
    </div>
  )
}
