import type React from 'react'

import { cn } from '../utils'

import { Icon } from './Icon'

type Step = {
  number: number
  label: string
}
interface VerticalSteppedProgressProps {
  steps: Step[]
  currentNumber: number
}

const IconOrNumber = ({
  number,
  isPastStep,
  isCurrentNumber,
  isFutureStep,
}: {
  number: number
  isPastStep: boolean
  isCurrentNumber: boolean
  isFutureStep: boolean
}) => {
  return (
    <div
      className={cn('w-9 h-9 flex items-center justify-center rounded-full', {
        '': isCurrentNumber,
      })}
    >
      <div
        className={cn(
          'rounded-full h-7 w-7 flex items-center justify-center text-xl font-semibold text-neutral-500',
          {
            '': isFutureStep,
            'bg-neutral-950 dark:bg-primary text-white': isPastStep,
            'text-neutral-950': isCurrentNumber,
          },
        )}
      >
        {isPastStep ? (
          <Icon name="Check" className="w-4 h-4" />
        ) : (
          <div>{number > 9 ? number : `0${number}`}</div>
        )}
      </div>
    </div>
  )
}

export const VerticalSteppedProgress: React.FC<
  VerticalSteppedProgressProps
> = ({ steps, currentNumber }) => {
  return (
    <div className={`w-full grid grid-cols-4 items-center`}>
      {steps.map(({ number, label }) => {
        const isCurrentNumber = number === currentNumber
        const isPastStep = number < currentNumber
        const isFutureStep = number > currentNumber

        return (
          <div
            key={number}
            className={cn(
              'flex flex-col items-center w-full border-b pb-5 gap-1',
              {
                'border-neutral-300': !isCurrentNumber,
                'border-neutral-950 border-b-2': isCurrentNumber,
              },
            )}
          >
            <IconOrNumber
              number={number}
              isPastStep={isPastStep}
              isCurrentNumber={isCurrentNumber}
              isFutureStep={isFutureStep}
            />
            <div
              className={cn(
                'text-neutral-500 text-xs sm:text-sm font-normal leading-tight',
                {
                  'text-neutral-950': isCurrentNumber || isPastStep,
                },
              )}
            >
              {label}
            </div>
          </div>
        )
      })}
    </div>
  )
}
