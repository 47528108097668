import type React from 'react'

import { ThinBadge } from './ThinBadge'
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip'

interface IssueTag {
  tag: string
}

export const issueTagConfig = {
  'accessibility-wcag2a': {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'WCAG 2.0 AA',
    hint: 'Accessibility WCAG 2.0 Level A - https://www.w3.org/TR/WCAG20/',
  },
  'accessibility-wcag2aa': {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'WCAG 2.0 AA',
    hint: 'Accessibility WCAG 2.0 Level AA - https://www.w3.org/TR/WCAG20/',
  },
  'accessibility-wcag2aaa': {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'WCAG 2.0 AA',
    hint: 'Accessibility WCAG 2.0 Level AAA - https://www.w3.org/TR/WCAG20/',
  },
  'accessibility-wcag21a': {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'WCAG 2.1 A',
    hint: 'Accessibility WCAG 2.1 Level A - https://www.w3.org/TR/WCAG21/',
  },
  'accessibility-wcag21aa': {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'WCAG 2.1 AA',
    hint: 'Accessibility WCAG 2.1 Level AA - https://www.w3.org/TR/WCAG21/',
  },
  'accessibility-wcag22aa': {
    colorClasses: {
      bg: 'bg-orange-600',
      text: 'text-orange-600',
    },
    label: 'WCAG 2.2 AA',
    hint: 'Accessibility WCAG 2.2 Level AA - https://www.w3.org/TR/WCAG22/',
  },
}

export const IssueTag: React.FC<IssueTag> = ({ tag }: { tag: string }) => {
  const tagConf = issueTagConfig[tag as keyof typeof issueTagConfig]

  if (!tagConf) {
    return (
      <ThinBadge
        colorClasses={{ bg: 'bg-neutral-600', text: 'text-neutral-600' }}
      >
        {tag}
      </ThinBadge>
    )
  }

  const badge = (
    <ThinBadge colorClasses={tagConf.colorClasses}>{tagConf.label}</ThinBadge>
  )

  if (tagConf.hint) {
    return (
      <Tooltip>
        <TooltipTrigger>{badge}</TooltipTrigger>
        <TooltipContent>{tagConf.hint}</TooltipContent>
      </Tooltip>
    )
  }

  return badge
}
