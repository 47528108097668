import type React from 'react'

import { clsx as classNames } from 'clsx'
import { cva } from 'cva'

import { Badge } from './Badge'

interface IssueStatusProps {
  status: 'active' | 'ignored' | 'resolved'
  className?: string
}

const classNameBuilder = getClassNameBuilder()

export const IssueStatus: React.FC<IssueStatusProps> = ({
  status,
  className: propClassName,
}) => {
  const builtClassNames = classNameBuilder({
    status,
  })

  return (
    <Badge
      size="custom"
      color="custom"
      className={classNames(builtClassNames, propClassName)}
    >
      Status: <span className="font-bold ml-1 capitalize">{status}</span>
    </Badge>
  )
}

function getClassNameBuilder() {
  return cva([`py-1.5 px-3 text-primary-foreground rounded-full border-0`], {
    variants: {
      status: {
        active: `bg-primary`,
        ignored: `bg-neutral-400`,
        resolved: `bg-green-500`,
      },
    },
    defaultVariants: {
      status: `active`,
    },
  })
}
