import type { IconName } from './Icon'
import type React from 'react'

import { SparklesIcon } from '@heroicons/react/20/solid'
import { cva } from 'cva'
import Link from 'next/link'

import { cn } from '../utils'

import { Badge } from './Badge'
import { Icon } from './Icon'
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from './Tooltip'

function getSidebarItemClassBuilder() {
  return cva(
    [
      `flex w-full items-center border rounded-md text-sm font-medium text-current transition-colors duration-300 p-2`,
    ],
    {
      variants: {
        collapsed: {
          true: `justify-center space-x-0 [&>span]:hidden`,
          false: `space-x-2`,
        },
        active: {
          true: `bg-neutral-200 font-medium dark:bg-primary-300/10 dark:text-primary-contrast`,
          false: `ring-transparent hover:bg-neutral-200 active:bg-gray-200 dark:bg-dark-900 dark:text-gray-300 dark:hover:bg-dark-800 dark:hover:text-white dark:active:bg-dark-700 dark:active:bg-dark-700`,
        },
        testDetection: {
          true: 'hover:border-amber-500 hover:bg-transparent active:bg-neutral-200',
          false: 'border-transparent',
        },
      },
      compoundVariants: [
        {
          collapsed: true,
          active: true,
          className: `bg-neutral-200 dark:bg-primary-500/10`,
        },
        {
          collapsed: false,
          active: true,
          className: `font-medium dark:bg-primary-300/10 dark:text-primary-contrast`,
        },
        {
          collapsed: true,
          active: false,
          className: `dark:text-primary-contrast`,
        },
        {
          active: true,
          testDetection: true,
          className: 'bg-transparent border-amber-500',
        },
        {
          active: false,
          testDetection: true,
          className: 'border-transparent',
        },
      ],
    },
  )
}

interface MenuItemBaseProps {
  path?: string
  onClick?: () => void
  icon: IconName
  iconClassName?: string
  active?: boolean
  testDetection?: boolean
  collapsed?: boolean
  sideLabel?: string
}

interface MenuItemLinkProps extends MenuItemBaseProps {
  path: string
}

interface MenuItemButtonProps extends MenuItemBaseProps {
  onClick: () => void
}

type MenuItemProps = MenuItemButtonProps | MenuItemLinkProps

export function MenuItem({
  path,
  onClick,
  children,
  icon,
  iconClassName,
  sideLabel,
  active = false,
  collapsed = false,
  testDetection = false,
}: React.PropsWithChildren<MenuItemProps>) {
  const className = getSidebarItemClassBuilder()({
    collapsed,
    active,
    testDetection,
  })

  const inner = (
    <>
      {collapsed ? (
        <Tooltip>
          <TooltipTrigger>
            <Icon name={icon} />
          </TooltipTrigger>

          <TooltipPortal>
            <TooltipContent side={'right'} sideOffset={20}>
              {children}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ) : (
        <>
          <Icon name={icon} className={iconClassName} />
          <div>{children}</div>
          <div className="grow" />
          {sideLabel && !testDetection && (
            <Badge size="custom" className="text-xs rounded-2xl py-0.5 px-1.5">
              {sideLabel}
            </Badge>
          )}
          {!active && testDetection && (
            <div className="rounded-sm bg-amber-500 p-0.5">
              <SparklesIcon className="h-4 text-white dark:text-dark-800" />
            </div>
          )}
          {active && (
            <>
              {testDetection && <SparklesIcon className="h-4 text-amber-500" />}
              <div
                className={cn(
                  'w-[3px] rounded-sm h-5',
                  testDetection
                    ? 'bg-amber-500 dark:bg-amber-500'
                    : 'bg-neutral-950 dark:bg-neutral-100',
                )}
              />
            </>
          )}
        </>
      )}
    </>
  )

  if (path !== undefined) {
    return (
      <Link key={path} href={path} className={className}>
        {inner}
      </Link>
    )
  }

  return (
    <button className={className} onClick={onClick}>
      {inner}
    </button>
  )
}
