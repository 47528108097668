import type { ReactNode } from 'react'
import type React from 'react'

import { cn } from '../utils'

import { Heading } from './Heading'
import { Text } from './Text'

interface EmptyBlockProps {
  title?: string
  subtitle?: string
  children?: ReactNode
  className?: string
}
const EmptyBlock: React.FC<EmptyBlockProps> = ({
  title = 'No results',
  subtitle,
  children,
  className,
}) => {
  return (
    <div className={cn('flex flex-col items-center gap-1', className)}>
      <Heading size="small">{title}</Heading>
      {subtitle && (
        <Text size="medium" className="text-neutral-500">
          {subtitle}
        </Text>
      )}
      {children}
    </div>
  )
}
EmptyBlock.displayName = 'EmptyBlock'

export { EmptyBlock }
