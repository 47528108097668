'use client'

import type { InputProps } from './'

import { forwardRef, useState } from 'react'

import { cn } from '../utils'

import { Icon, TextFieldInput, Button } from './'

const PasswordField = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false)
    const disabled =
      props.value === '' || props.value === undefined || props.disabled

    const inputType = showPassword ? 'text' : 'password'

    return (
      <div className="relative">
        <TextFieldInput
          type={inputType}
          className={cn('hide-password-toggle pr-10', className)}
          ref={ref}
          {...props}
        />
        <Button
          type="button"
          variant="ghost"
          size="small"
          className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
          onClick={() => setShowPassword((prev) => !prev)}
          disabled={disabled}
        >
          {showPassword && !disabled ? (
            <Icon name="Eye" size="small" aria-hidden="true" />
          ) : (
            <Icon name="EyeSlash" size="small" aria-hidden="true" />
          )}
          <span className="sr-only">
            {showPassword ? 'Hide password' : 'Show password'}
          </span>
        </Button>

        {/* hides browsers password toggles */}
        <style>{`
					.hide-password-toggle::-ms-reveal,
					.hide-password-toggle::-ms-clear {
						visibility: hidden;
						pointer-events: none;
						display: none;
					}
				`}</style>
      </div>
    )
  },
)
PasswordField.displayName = 'InputPassword'

export { PasswordField }
