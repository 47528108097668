import type { ReactNode } from 'react'

import {
  Icon,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
  If,
} from './'

interface StatBoxParams {
  title: string
  isLoading: boolean
  mainMetric: ReactNode
  subTitle?: string
  tooltipText?: string
}

export const StatBox = ({
  title,
  mainMetric,
  isLoading,
  subTitle,
  tooltipText,
}: StatBoxParams) => {
  return (
    <div className="basis-1/4 border-l first:border-0 group/item justify-center">
      <div className="flex flex-col justify-between px-5 group-first/item:pl-0 group-last/item:pr-0">
        <div className="flex justify-between">
          <div className="text-sm font-normal mb-4">{title}</div>
          {tooltipText && (
            <Tooltip>
              <TooltipTrigger className="w-5 h-5" asChild>
                <span>
                  <Icon
                    name="InformationCircle"
                    size="medium"
                    className="text-neutral-400"
                  />
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>{tooltipText}</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )}
        </div>
        <If condition={!isLoading} fallback={<Skeleton className="h-8 w-8" />}>
          <div className="text-3xl font-bold">{mainMetric}</div>
        </If>
        {subTitle && (
          <p className="text-xs text-muted-foreground">{subTitle}</p>
        )}
      </div>
    </div>
  )
}
