'use client'

import type React from 'react'

import { forwardRef, useCallback, useState } from 'react'

type Props = React.InputHTMLAttributes<unknown>

export const RangeField = forwardRef<React.ElementRef<'input'>, Props>(
  function InputRangeComponent(
    { className, children, defaultValue, onInput, ...props },
    ref,
  ) {
    const value = (props.value || defaultValue || 0) as number

    const [bubbleProps, setBubbleProps] = useState(
      getBubbleProps({
        value,
        min: Number(props.min),
        max: Number(props.max),
      }),
    )

    const onInputHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onInput) {
          onInput(e)
        }

        const bubbleProps = getBubbleProps({
          value: Number(e.target.value),
          min: Number(props.min),
          max: Number(props.max),
        })

        setBubbleProps(bubbleProps)
      },
      [onInput, props.max, props.min],
    )

    return (
      <div className={'flex flex-col space-y-1'}>
        <input
          className={className}
          {...props}
          onInput={onInputHandler}
          ref={ref}
          type={'range'}
        />

        <span className={'relative text-xs box-content'}>
          {bubbleProps.value !== null && (
            <span
              className={'absolute top-1'}
              style={{
                left: bubbleProps.left,
              }}
            >
              {bubbleProps.value}
            </span>
          )}
        </span>
      </div>
    )
  },
)

function getBubbleProps(range: {
  value: number | null
  min?: number | null
  max?: number | null
}) {
  const leftStart = 2 // 2px
  const value = range.value
  const min = range.min ? range.min : 0
  const max = range.max ? range.max : 100

  if (!value) {
    return {
      value: 0,
      left: `${leftStart}px`,
    }
  }

  const left = Number(((value - min) * 100) / (max - min))

  return {
    value,
    left: `calc(${leftStart}px + ${left}% - ${16 * (left / 100)}px)`,
  }
}
