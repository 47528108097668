import Link from 'next/link'
import React from 'react'

import { cn } from '../utils'

interface BreadcrumbsProps {
  crumbs: Crumb[]
  lastIsLink?: boolean
}

export interface Crumb {
  title: string
  href?: string
}

const CrumbComp: React.FC<Crumb> = ({ title, href }) => {
  const classNames = 'p-0 h-5 block text-sm font-medium text-sm'
  return href ? (
    <Link href={href} className={classNames}>
      {title}
    </Link>
  ) : (
    <div
      className={cn(classNames, 'text-neutral-400')}
      style={{
        maxWidth: '50ch',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {title}
    </div>
  )
}

const Separator: React.FC = () => (
  <div className="leading-5 text-sm text-neutral-400">/</div>
)

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  crumbs,
  lastIsLink = false,
}) => {
  if (!crumbs.length) {
    return null
  }

  return (
    <div className="flex flex-row leading-5 space-x-2">
      {crumbs &&
        crumbs.map((crumb, index) => {
          const isLast = index === crumbs.length - 1

          return (
            <React.Fragment key={crumb.title + index}>
              <CrumbComp
                title={crumb.title}
                href={
                  (lastIsLink && isLast) || !isLast ? crumb.href : undefined
                }
              />
              {!isLast && <Separator />}
            </React.Fragment>
          )
        })}
    </div>
  )
}
