import type Run from 'types/src/run'

import { runStatusConfig } from '../lib/runStatusConfig'

import { RunResultStatusCircle } from './RunResultStatusCircle'
import { ThinBadge } from './ThinBadge'

export interface RunStatusBadgeProps {
  run: Pick<Run, 'status' | 'result'>
}

export function RunStatusBadge({ run }: RunStatusBadgeProps) {
  const badges = {
    INITIATED: <RunResultStatusCircle run={run} withLabel />,
    RUNNING: <RunResultStatusCircle run={run} withLabel />,
    COMPLETED: (
      <ThinBadge colorClasses={runStatusConfig.COMPLETED.colorClasses}>
        {runStatusConfig.COMPLETED.label}
      </ThinBadge>
    ),
    ERROR: (
      <ThinBadge colorClasses={runStatusConfig.ERROR.colorClasses}>
        {runStatusConfig.ERROR.label}
      </ThinBadge>
    ),
  }

  return badges[run.status]
}
